import React from 'react'
import {Link} from 'gatsby'
import {StaticImage} from "gatsby-plugin-image"
import App from '../components/app'
import BannerLayout from '../components/banner-layout'
import Encomendar from '../components/encomendar'

import ogImage from "../assets/images/unsplash/davide-cantelli/home-banner.jpg"

const title = "Delícias da Lini"
const description = "Comida Caseira por encomenda em Pinhal Novo"
const bannerImage = "../assets/images/unsplash/davide-cantelli/home-banner.jpg"
const BannerImage = <StaticImage src={bannerImage} alt={title} className="uk-height-medium uk-animation-fade"/>
const LasagaImage = <StaticImage src="../assets/images/unsplash/sunorwind/lasanha.jpg" alt="Lasanha da Lini" className="uk-height-medium uk-animation-fade" style={{
    position: 'static'
  }}/>
const FeijoadaImage = <StaticImage src="../assets/images/feijoada-brasileira-01.jpg" alt="Feijoada Brasileira da Lini" className="uk-height-medium uk-animation-fade" style={{
    position: 'static'
  }}/>
const PaoDeQueijoImage = <StaticImage src="../assets/images/pao-de-queijo-01.jpg" alt="Pãozinhos de Queijo da Lini" className="uk-height-medium uk-animation-fade" style={{
    position: 'static'
  }}/>
/*
const VinhosImage = <StaticImage src="../assets/images/vinhos-01.jpg" alt="Vinhos Seleccionados" className="uk-height-medium uk-animation-fade" style={{
    position: 'static'
  }}/>
*/

export default function Home({location}) {
  return <App location={location} title={title} description={description} image={ogImage}>
    <BannerLayout title={title} bannerImage={BannerImage}>
      <section className="uk-section uk-section-small">
        <div className="uk-container uk-text-center">
          <p className="uk-text-lead">{description}</p>
          <Encomendar/>
        </div>
      </section>
      <section className="uk-section uk-section-small">
        <div className="uk-card uk-card-secondary uk-grid-collapse uk-child-width-1-2@m" uk-grid="uk-grid">
          <div className="uk-card-media-left uk-cover-container">
            {LasagaImage}
          </div>
          <div>
            <div className="uk-card-body">
              <h3 className="uk-card-title">Lasanha</h3>
              <p>Lasanhas tradicionais, vegetarianas e veganas. Para uma, duas e quatro pessoas. Encomenda um dia antes.</p>
              <Link className="uk-button uk-button-default" to="/lasanha/">Saiba mais</Link>
            </div>
          </div>
        </div>
        <div className="uk-card uk-card-primary uk-grid-collapse uk-child-width-1-2@m" uk-grid="uk-grid">
          <div className="uk-flex-last@s uk-card-media-right uk-cover-container">
            {FeijoadaImage}
          </div>
          <div>
            <div className="uk-card-body">
              <h3 className="uk-card-title">Feijoada Brasileira</h3>
              <p>Feijoada Brasileira completa. Com arroz, couve, farofa de mandioca e laranja. Receita tradicional da família. Serve quatro a seis pessoas. Encomenda um dia antes.</p>
              <Link className="uk-button uk-button-default" to="/feijoada-brasileira/">Saiba mais</Link>
            </div>
          </div>
        </div>
        <div className="uk-card uk-card-secondary uk-grid-collapse uk-child-width-1-2@m" uk-grid="uk-grid">
          <div className="uk-card-media-left uk-cover-container">
            {PaoDeQueijoImage}
          </div>
          <div>
            <div className="uk-card-body">
              <h3 className="uk-card-title">Pão de Queijo</h3>
              <p>Tradicional com queijo só, com tomate seco ou com orégão. Perfeito para um lanche da tarde. Encomenda um dia antes.</p>
              <Link className="uk-button uk-button-default" to="/pao-de-queijo/">Saiba mais</Link>
            </div>
          </div>
        </div>
      </section>
      <section className="uk-section uk-section-small">
        <div className="uk-container uk-text-center">
          <p className="uk-text-lead uk-margin-bottom">Bom apetite!</p>
        </div>
      </section>
    </BannerLayout>

  </App>
}
